@import "../styles/common/variables";

.received-tokens {
  font-size: 1.6rem;
  margin-top: 6rem;
  color: $dark-blue;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 991px) {
  .received-token-div {
    width: 57%;
  }
}

@media only screen and (max-width: 575px) {
  .received-tokens {
    margin-top: 0;
  }
}
