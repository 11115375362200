@import "../../styles/common/variables";

.modal-header {
  width: 10px;
  margin-left: auto;
}

.success-img {
  text-align: center;
}

.title {
  font-size: 1.6rem;
  color: $dark-blue;
  text-align: center;
  font-weight: 700;
  margin-bottom: 1rem;
}

.sub-title-div {
  width: 100%;
  padding: 0 20% 0 20%;
}

.sub-title {
  font-size: 1.4rem;
  color: $grey;
  text-align: center;
  line-height: 2.5rem;
}

.modal-backdrop {
  background-color: $primary !important;
  opacity: 0.5 !important;
  filter: blur(50px);
  -webkit-filter: blur(50px);
}

.header-div {
  width: 1rem;
  margin-left: auto;
}

@media only screen and (max-width: 991px) {
  .title {
    margin: 0;
  }
}

@media only screen and (max-width: 575px) {
  .title {
    margin-bottom: 0.7rem;
  }

  .sub-title-div {
    padding: 0 10% 0 10%;
    .sub-title {
      width: 100%;
    }
  }
}
