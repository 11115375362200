@import "../../styles/common/variables";

.send-token-sec {
  background-color: #fff;
  border: 1px solid $blue;
  width: 50%;
  border-radius: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  margin: 1.9rem auto;
  justify-content: space-between;
  .input-sec {
    width: 100%;
    cursor: text;

    & input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 1.6rem;

      &::placeholder {
        font-size: 1.6rem;
        color: $grey;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .btn-div {
    width: 24%;
    .send-token-btn {
      background-color: $blue;
      color: #fff;
      width: 100%;
      height: 5.1rem;
      font-size: 1.6rem;
      border-radius: 0.5rem;
      &:hover {
        color: #fff;
        background-color: hsl(207, 100%, 42%);
      }
    }
  }
}

.send-token-sec-class-two {
  background-color: #fff;
  border: 1px solid $blue;
  width: 50%;
  border-radius: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  margin: 1.9rem auto;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .input-sec {
    width: 100%;
    cursor: text;

    & input {
      width: 100%;
      height: 100%;
      border: 0;
      font-size: 1.6rem;

      &::placeholder {
        font-size: 1.6rem;
        color: $grey;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .btn-div {
    width: 24%;
    .send-token-btn {
      background-color: $blue;
      color: #fff;
      width: 100%;
      height: 5.1rem;
      font-size: 1.6rem;
      border-radius: 0.5rem;
      &:hover {
        color: #fff;
        background-color: hsl(207, 100%, 42%);
      }
    }
  }
}

.error_msg {
  color: hsl(0deg 99% 50%);
  font-weight: 500;
  margin-left: 25.5%;
  text-align: left;
}

@media only screen and (max-width: 1399px) {
  .send-token-sec {
    .input-sec {
      width: 82%;
    }
  }

  .send-token-sec-class-two {
    .input-sec {
      width: 82%;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .send-token-sec {
    .input-sec {
      width: 67%;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .btn-div {
      width: 30%;
    }
  }

  .send-token-sec-class-two {
    .input-sec {
      width: 67%;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .btn-div {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .send-token-sec {
    width: 74%;
    .input-sec {
      width: 72.2%;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .send-token-btn {
      width: 120%;
    }
  }

  .send-token-sec-class-two {
    width: 74%;
    .input-sec {
      width: 72.2%;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .send-token-btn {
      width: 120%;
    }
  }
  .error_msg {
    margin-left: 13.5%;
  }
}

@media only screen and (max-width: 575px) {
  .send-token-sec {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 0.5rem 0.5rem 0.5rem 0.9rem;
    .send-token-btn {
      width: 100%;
    }

    .btn-div {
      width: 55%;
      .send-token-btn {
        width: 100%;
      }
    }
    .input-sec {
      width: 60%;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .send-token-sec-class-two {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 0.5rem 0.5rem 0.5rem 0.9rem;
    .send-token-btn {
      width: 100%;
    }

    .btn-div {
      width: 55%;
      .send-token-btn {
        width: 100%;
      }
    }
    .input-sec {
      width: 60%;
      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .error_msg {
    margin-left: 1.5%;
  }
}
