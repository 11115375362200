@import "../../styles/common/variables";

.received-token-div {
  background-color: $general-backgound;
  padding: 1.5rem 1.6rem 1.5rem 1.6rem;
  width: 25%;
  border: 1px solid $light-grey;
  border-radius: 0.5rem;
  border-bottom: 2px solid $grey;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  justify-content: space-between;
  .token {
    font-size: 1.6rem;
    color: $grey;
    word-break: break-all;
  }

  .icon {
    font-size: 1.6rem;
    color: $grey;
    align-self: center;
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 1399px) {
  .received-token-div {
    width: 36.49% !important;
  }
}

@media only screen and (max-width: 1199px) {
  .received-token-div {
    width: 46.9% !important;
  }
}

@media only screen and (max-width: 991px) {
  .received-token-div {
    width: 57% !important;
  }
}

@media only screen and (max-width: 575px) {
  .received-token-div {
    margin-left: 1%;
    margin-right: 1%;
    width: 98% !important;
    .token {
      overflow: hidden;
      max-width: 500px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
