/** @format */
$primary: #073d83;
$light-primary: #e8f0fe;
$grey: #868686;
$blue: #007de4;
$dark-blue: #1e2835;
$black-one: #0d0f12;
$light-grey: #dadce0;
$black-two: #161616;
$general-backgound: #f8f8f8;
$white-shade: #dadce0;
$theme-colors: (
  "primary": #073d83,
);
