@import "common/variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
  font-size: 62.5%;
  font-family: "Lato", sans-serif;
}

.info-icon {
  color: #005397;
  border: 1.5px solid #005397;
  border-radius: 50%;
  padding: 2px;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.toast-info {
  font-size: 1.6rem;
  font-weight: 700 !important;
}

.spinner-inner {
  width: 3rem;
  height: 3rem;
  color: #007de4;
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 10000;
}
