@import "../../styles/common/variables";


.footer-div {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  justify-content: space-between;

  .footer-txt {
    color: $grey;
    font-size: 1.3rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .footer-div {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 999;
    background-color: #fff;
    padding: 0.5rem;
  }
}
