@import '../../styles/common/variables';

.main {
  .img {
    margin-right: 2.1rem;
  }

  .logo-line {
    font-size: 3rem;
    font-weight: 100 !important;
    padding-top: 0.15rem;
    color: $light-grey;
  }

  .header-text-div {
    margin-top: 1.5rem;

    .logo-title {
      font-size: 1.6rem;
      color: $black-one;
    }

    .faucet-text {
      font-weight: 600;
    }
  }

  .logo-titl-div {
    flex: 1;
  }

  .support-text-div {
    margin-right: 1.5rem;
    .support-txt {
      color: $black-two;
      font-size: 1.6rem;
    }
  }

  .btn-div {
    // width: 12%;
    margin: 1.5rem 2.1rem 1.5rem 0;
    .send-token-btnn {
      color: hsl(207, 100%, 42%);
      font-size: 1.6rem;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: #073d83;
        text-decoration: underline;
      }
    }
  }
}

.testnet-url {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
