@import '../../styles/common/variables';

.banner-img-div {
  position: relative;
  background-image: url('https://account-files-bucket.s3.ap-south-1.amazonaws.com/faucet/assets/images/sendtokens-banner.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  .banner-div-ht-change {
    height: 340px;
  }
  .banner-inner-content {
    padding: 40px;
    width: 100%;
    .cosmos-txt {
      font-size: 4rem;
      color: #fff;
      font-weight: 600;
      text-align: center;
    }
    .cosmos-txt-subtitle-sec {
      width: 100%;

      .cosmos-txt-subtitle {
        font-size: 1.4rem;
        color: $light-primary;
        margin-left: auto;
        margin-bottom: 0px;
        margin-right: auto;
        text-align: center;
        line-height: 2.7rem;
        width: 30%;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .banner-img-div {
    .cosmos-txt-subtitle {
      width: 42% !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .banner-inner-content {
    .cosmos-txt-subtitle-sec {
      .cosmos-txt-subtitle {
        width: 60% !important;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .title {
    .banner-inner-content {
      top: 3rem !important;
      .cosmos-txt-subtitle-sec {
        .cosmos-txt-subtitle {
          width: 90% !important;
        }
      }
    }
  }
}
