@import "../../styles/common/variables";

.modal-content {
  padding: 3rem;
  border-radius: 0.5rem !important;
  border: 0 !important;
  margin-top: 50%;
  .modal-header {
    margin-left: 0;
    width: 100%;
  }
}

.modal-title {
  font-size: 1.6rem;
  color: $dark-blue;
  font-weight: 700;
  width: 100%;
}

.modal-div {
  padding: 2rem;
}

.title-div {
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.text-area {
  background-color: $general-backgound;
  font-size: 1.7rem;
  border: 0;
  padding: 3rem;
  min-height: 10.4rem;
  max-height: 10.4rem;
  border-radius: 0.5rem;
  color: $dark-blue;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $dark-blue !important;
    font-size: 1.8rem !important;
  }
}

.icon {
  color: $light-grey;
  font-size: 1.7rem;
  cursor: pointer;
}

.confirm-btn {
  background-color: $blue !important;
  color: #fff !important;
  width: 100%;
  height: 3rem;
  font-size: 1.3rem !important;
  border-radius: 0.5rem !important;
}

@media only screen and (max-width: 575px) {
  .modal-body {
    margin: 15px 0 !important;
  }
  .icon-div {
    top: 30px;
  }
}
